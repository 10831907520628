<template>
    <div class="question-item">
        <div class="s-head">
            <h2 :required="required" :style="{ color: pageInfo.style.questionColor }">{{title}}</h2>
            <div>最多上传三张图片和一个视频</div>
        </div>
        <div class="image-list">
            <div class="add">+</div>
            <div><img src="../../../assets/demo1.png"/></div>
            <div><img src="../../../assets/demo2.png"/></div>
        </div>
    </div>
</template>
<script>
export default {
    name: "ImageUpload",
    props: ["title",  "required", "pageInfo"]
}
</script>
<style lang="less" scoped>
    .s-head {
        overflow: hidden;
        h2 {
            float: left;
        }

        div {
            float: left;
            margin-left: 30px;
            font-size: 12px;
            color: #8494A6;
            height: 20px;
            line-height: 22px;
        }
    }
    .image-list {
        display: flex;

        .add {
            border: 1px dashed #D4DBE1;
            background-color: #F8F8F8;
            font-size: 66px;
            line-height: 100px;
            text-align: center;
            color: #8494A6;
        }

        div {
            margin-right: 18px;
            width: 100px;
            height: 100px;
            box-sizing: border-box;

            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }
</style>